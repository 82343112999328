import service from "@/api/service";
import { GetResponse } from "@/api/rank-setting/response";
import { GetRequest } from "@/api/rank-setting/request";

/**
 * スタンプカードランク別特典チケット設定取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-rank-setting", getRequest);
  return response.data as GetResponse;
}
