import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/stamp-shop/response";
import * as StampShopAPI from "@/api/stamp-shop";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "stamp-shop/get";

/**
 * スタンプカード機能有効店舗情報取得API（/get-stamp-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async get() {
    const getResponse = await StampShopAPI.get();
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
