import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import { UlFileRegisiterTabType } from "@/utils/enums";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  DetailRequest,
  RegisterCompleteTicket,
  RegisterRankSetting,
  RegisterRankStamp,
  RegisterRankTicket,
  RegisterRequest
} from "@/api/stamp/request";
import { GetRequest as GetCompRequest } from "@/api/comp-setting/request";
import { GetRequest as GetStampRequest } from "@/api/stamp-rank/request";
import { GetRequest as GetRankRequest } from "@/api/rank-setting/request";
import { GetRequest as GetPreviewRequest } from "@/api/stamp-ticket-preview/request";
import StampDetail from "@/store/stamp/detail";
import StampModify from "@/store/stamp/modify";
import StampRegister from "@/store/stamp/register";
import StampShopGet from "@/store/stamp-shop/get";
import CompSettingGet from "@/store/comp-setting/get";
import StampRankGet from "@/store/stamp-rank/get";
import RankSettingGet from "@/store/rank-setting/get";
import StampTicketPreviewGet from "@/store/stamp-ticket-preview/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import WindowOpen from "@/utils/window-open";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import { GetRequest as GetAgreementRequest } from "@/api/shop-agreement/request";
import { BusinessServiceStorage } from "@/store/business-service-storage";

const enum NextLabelEnum {
  NEXT = "次へ",
  SAVE = "保存する"
}

const enum RewardTicketType {
  Intermediate,
  Rank
}

interface RankShopStamp {
  shopId: number | null;
  stamps: RegisterRankStamp[];
}

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UIDatePicker,
    UlFileRegister,
    quillEditor
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null; // スタンプID

  // タイトル
  headingMain = "スタンプ";
  headingSub = "Stamps";

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        // ["link", "image"],
        ["link"],
        ["clean"]
      ]
    }
  };

  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // 1ページ目の表示かどうか
  isFirstPage = true;

  // 次へ or 保存のラベル
  get nextLabel() {
    if (!this.isFirstPage || this.inputParams.stampFlg === 0) {
      return NextLabelEnum.SAVE;
    } else {
      return NextLabelEnum.NEXT;
    }
  }

  initMainImageUrl = null as string | null;
  initBgImageUrl = null as string | null;
  initStampImageUrl = null as string | null;

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  inputParams = {
    /** スタンプカードID */
    id: null as number | null,
    /** 店舗ID */
    shopId: 0,
    /** 利用可能フラグ 0:利用しない、1:利用する */
    stampFlg: 0,
    /** スタンプカードタイトル */
    title: null,
    /** スタンプカード詳細 */
    description: null,
    /** メイン画像編集フラグ */
    mainImageEditFlg: 0,
    /** メイン画像 */
    mainImage: null,
    /** t_shop_image_histories.id */
    mainImageId: null,
    /** 利用店舗 */
    userShops: [],
    /** スタンプ自動付与設定 0:利用しない、1:利用する */
    autoFlg: 0,
    /** 次回来店メモ設定 0:利用しない、1:利用する */
    isMemo: 0,
    /** スタンプ見出し */
    headline: null,
    /** スタンプ背景画像編集フラグ */
    bgImageEditFlg: 0,
    /** スタンプ背景画像 */
    bgImage: null,
    /** t_shop_image_histories.id */
    bgImageId: null,
    /** 有効期限モード 0:有効期限なし、1:利用開始日から設定、2:最終付与日から設定、3:日にち指定で設定 */
    expirationType: 0,
    /** 有効日数（expirationTypeが1か2の場合） */
    expirationNum: null,
    /** 有効期限（expirationTypeが3の場合） */
    expirationDate: null,
    /** 自動更新機能 0:利用しない、1:利用する */
    autoUpdatingFlg: null,
    /** 自動更新周期タイプ 1:日、2:週、3:月、4:年 */
    autoUpdatingType: 1,
    /** 自動更新周期 */
    autoUpdatingNum: null,
    /** 初回ダウンロード特典スタンプ数 */
    startPoint: null,
    /** 初回ダウンロード特典スタンプ画像編集フラグ */
    startPointImageEditFlg: 0,
    /** 初回ダウンロード特典スタンプ画像 */
    startPointImage: null,
    /** t_shop_image_histories.id */
    startPointImageId: null,
    /** スタンプコンプリート数 */
    completeNum: null,
    /** 途中特典付与 0:利用しない、1:利用する */
    incompleteFlg: null,
    /** 途中特典付与1回目 0:利用しない、1:利用する */
    incompleteFirst: 0,
    /** 途中特典付与2回目 0:利用しない、1:利用する */
    incompleteSecond: 0,
    /** 途中特典1回目チケット配列 */
    incompleteFirstTicket: {},
    /** 途中特典2回目チケット配列 */
    incompleteSecondTicket: {},
    /** コンプリート特典チケット配列 */
    completeTicket: {},/** 特典チケット有期限 */
    incompleteExpireNum: null,
    /** ランク設定利用 0:利用しない、1:利用する */
    rankFlg: 0,
    /** ランク特典設定 1:チケット特典、2:スタンプ付与 */
    rankFuyoType: 1,
    /** ランク設定配列 */
    rankSetting: null,
    /** ランク別スタンプ付与数設定 1:全店共通、2:支店別 */
    stampSettingType: 1,
    /** ランク別スタンプ付与数配列 */
    rankStamps: null,
    /** ランク別特典チケット配列 */
    rankTickets: null,
    /** プレビューフラグ */
    isPreview: 0
  } as RegisterRequest;

  // OEM事業者ID
  businessId = 0 as number;
  compTicketInputParams = [] as RegisterCompleteTicket[];
  compTicketCompleteInputParams = {} as RegisterCompleteTicket;
  rankSettingInputParams = [] as RegisterRankSetting[];
  rankTicketInputParams = [] as RegisterRankTicket[];
  rankShopStampInputParams = [] as RankShopStamp[];

  expirationNums = [] as (number | null)[];
  autoUpdatingNums = [] as (number | null)[];

  rankUpRewardCount = 1;

  // ランク設定モーダル
  showRankSettingModal = false;
  rankSettingModalTitle = "";
  rankSettingModalText = "";
  rankName = null as string | null;
  completeCount = null as number | null;
  rankValidationErrors = [];

  // 特典チケット設定モーダル
  showRewardTicketModal = false;
  selectingIndex = 0;
  isComplete = false;
  rewardTicketModalType = RewardTicketType.Intermediate;
  rewardTicketModalTitle = null as string | null;
  rewardTicketModalText = null as string | null;
  rewardTicketTitle = null as string | null;
  rewardTicketImageEditFlg = 0 as 0 | 1;
  rewardTicketImage = null as string | null;
  rewardTicketImageId = null as number | null;
  rewardTicketContent = null as string | null;
  rewardTicketCaution = null as string | null;
  initRewardTicketImageUrl = null as string | null;
  rewardValidationErrors = [];
  //NEW_DEV-1999 cyber start
  resetImage = 1 as number;
  //NEW_DEV-1999 cyber end

  // 特典ポイント
  rewardStamp = null as number | null;
  // NEW_DEV-1997 cyber end
  previewCompNum = null as number | null;
  previewRankTitle = null as string | null;
  //NEW_DEV-1997 cyber end

  // パンくず
  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "アピール", disabled: true },
        { text: "スタンプ", disabled: true },
        { text: "店舗一覧", disabled: false, to: { name: "stamps" } },
        {
          text: "スタンプ一覧",
          disabled: false,
          to: {
            name: "stamps-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        { text: "店舗一覧", disabled: false, to: { name: "stamps" } },
        {
          text: "スタンプ一覧",
          disabled: false,
          to: {
            name: "stamps-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    return Number(this.$route.query.shopId) as number;
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get isEdit() {
    return !!this.id;
  }

  get activeRankRewardTab() {
    if (this.inputParams.rankFuyoType === 2) {
      return 1;
    } else {
      return 0;
    }
  }

  set activeRankRewardTab(val) {
    if (val === 1) {
      this.inputParams.rankFuyoType = 2;
    } else {
      this.inputParams.rankFuyoType = 1;
    }
  }

  get activePointShopTypeTab() {
    if (this.inputParams.stampSettingType === 2) {
      return 1;
    } else {
      return 0;
    }
  }

  set activePointShopTypeTab(val) {
    if (val === 0) {
      this.inputParams.stampSettingType = 1;
    } else {
      this.inputParams.stampSettingType = 2;
    }
  }

  get stampShopItems() {
    return StampShopGet.getItems.map(s => {
      return { id: Number(s.id), name: s.name };
    });
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  async created() {
    if (!this.shopId) {
      await this.$router.push({ name: "stamps" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    this.inputParams.shopId = this.shopId;
    for (let i = 0; i < 2; i++) {
      this.compTicketInputParams.push({
        id: null,
        completeNum: null,
        isComplete: 0,
        title: null,
        note: null,
        detail: null,
        image: null,
        imageId: null
      } as RegisterCompleteTicket);
    }
    this.rankSettingInputParams.push({
      id: null,
      rankNum: 1,
      name: null,
      num: null
    } as RegisterRankSetting);
    this.rankTicketInputParams.push({
      id: null,
      title: null,
      detail: null,
      note: null,
      rankNum: null,
      imageEditFlg: 0,
      image: null,
      imageId: null
    } as RegisterRankTicket);
    this.rankShopStampInputParams.push({
      shopId: this.shopId,
      stamps: []
    } as RankShopStamp);
    this.inputParams.rankStamps = [
      { id: null, rank: 1, shopId: null, stamp: null } as RegisterRankStamp
    ];

    await StampShopGet.get();
    if (!StampShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: StampShopGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
      return;
    }

    if (this.isEdit) {
      await StampDetail.detail(this.createDetailRequest());
      if (!StampDetail.isSuccess) {
        await Flash.setErrorNow({
          message: StampDetail.getMessage,
          showReloadButton: true
        } as ErrorAlert);
        return;
      }
      this.syncDetailParams();

      // 途中特典チケットの取得
      for (let i = 0; i < this.compTicketInputParams.length; i++) {
        if (this.compTicketInputParams[i].id) {
          await CompSettingGet.get({
            id: this.compTicketInputParams[i].id
          } as GetCompRequest);
          if (CompSettingGet.isSuccess) {
            let ticketDetailTmp = {
              title: CompSettingGet.getResults.title,
              image: CompSettingGet.getResults.image,
              detail: CompSettingGet.getResults.detail,
              note: CompSettingGet.getResults.note,
              imageEditFlg: CompSettingGet.getResults.imageEditFlg,
            };
            if (ticketDetailTmp.imageEditFlg === undefined) {
              if (ticketDetailTmp.image) {
                ticketDetailTmp.imageEditFlg = 1;
              } else {
                ticketDetailTmp.imageEditFlg = 0;
              }
            } else if (ticketDetailTmp.imageEditFlg && ticketDetailTmp.image == null) {
              ticketDetailTmp.imageEditFlg = 0;
            }
            if (ticketDetailTmp.imageId === undefined) {
              ticketDetailTmp.imageId = null;
            }
            if (ticketDetailTmp.imageEditFlg == 0 || ticketDetailTmp.imageId != null) {
              delete ticketDetailTmp.image;
            }
            switch (i) {
              case 0:
                this.inputParams.incompleteFirstTicket = ticketDetailTmp;
                break;
              case 1:
                this.inputParams.incompleteSecondTicket = ticketDetailTmp;
                break;
            }
            this.incompleteChange((i + 1), this.compTicketInputParams[i].completeNum);
          }
        }
      }
      if (this.compTicketCompleteInputParams.id) {
        await CompSettingGet.get({
          id: this.compTicketCompleteInputParams.id
        } as GetCompRequest);
        if (CompSettingGet.isSuccess) {
          this.compTicketCompleteInputParams.title =
            CompSettingGet.getResults.title;
          this.compTicketCompleteInputParams.image =
            CompSettingGet.getResults.image;
          this.compTicketCompleteInputParams.detail =
            CompSettingGet.getResults.detail;
          this.compTicketCompleteInputParams.note =
            CompSettingGet.getResults.note;
        }
      }

      // ランク設定の取得
      for (let i = 0; i < this.rankSettingInputParams.length; i++) {
        if (this.rankSettingInputParams[i].id) {
          await StampRankGet.get({
            id: this.rankSettingInputParams[i].id
          } as GetStampRequest);
          if (StampRankGet.isSuccess) {
            this.rankSettingInputParams[i].name = StampRankGet.getResults.name;
            this.rankSettingInputParams[i].num = StampRankGet.getResults.num;
          }
        }
      }

      // ランク特典チケットの取得
      for (let i = 0; i < this.rankTicketInputParams.length; i++) {
        if (this.rankTicketInputParams[i].id) {
          await RankSettingGet.get({
            id: this.rankTicketInputParams[i].id
          } as GetRankRequest);
          if (RankSettingGet.isSuccess) {
            this.rankTicketInputParams[i].title =
              RankSettingGet.getResults.title;
            this.rankTicketInputParams[i].detail =
              RankSettingGet.getResults.detail;
            this.rankTicketInputParams[i].note = RankSettingGet.getResults.note;
            this.rankTicketInputParams[i].image =
              RankSettingGet.getResults.image;
            this.rankTicketInputParams[i].imageEditFlg = 0;
            this.rankTicketInputParams[i].imageId = null;
          }
        }
      }
    }
    let requestAgrement = {
      shopId: String(this.shopId)
    } as GetAgreementRequest;
    await ShopAgreementAPI.get(requestAgrement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreements.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreements.line = true;
        } else if (item.agreementsType == 3) {
          this.agreements.web = true;
        }
      });
    }
  }

  async beforeDestroy() {
    await StampDetail.clearResponse();
    await StampModify.clearResponse();
    await StampRegister.clearResponse();
    await StampShopGet.clearResponse();
    await CompSettingGet.clearResponse();
    await StampRankGet.clearResponse();
    await RankSettingGet.clearResponse();
    await StampTicketPreviewGet.clearResponse();
  }

  async mounted() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }
  isShowRewardTicketButton(index: number) {
    return (
      index === 1 ||
      (this.compTicketInputParams[0].completeNum &&
        this.compTicketInputParams[1].completeNum)
    );
  }

  getRankName(num: number) {
    if (num === 11 || num === 12 || num === 13) {
      return `${num}thランク`;
    }
    if (num % 10 === 1) {
      return `${num}stランク`;
    }
    if (num % 10 === 2) {
      return `${num}ndランク`;
    }
    if (num % 10 === 3) {
      return `${num}rdランク`;
    }
    return `${num}thランク`;
  }

  setMainFile(data: string, id: number | null) {
    this.inputParams.mainImageEditFlg = 1;
    this.inputParams.mainImage = data;
    this.inputParams.mainImageId = id;
  }

  setBgFile(data: string, id: number | null) {
    this.inputParams.bgImageEditFlg = 1;
    this.inputParams.bgImage = data;
    this.inputParams.bgImageId = id;
  }

  setStampFile(data: string, id: number | null) {
    this.inputParams.startPointImageEditFlg = 1;
    this.inputParams.startPointImage = data;
    this.inputParams.startPointImageId = id;
  }

  unsetMainFile() {
    this.inputParams.mainImageEditFlg = 1;
    this.inputParams.mainImage = null;
    this.inputParams.mainImageId = null;
  }

  unsetBgFile() {
    this.inputParams.bgImageEditFlg = 1;
    this.inputParams.bgImage = null;
    this.inputParams.bgImageId = null;
  }

  unsetStampFile() {
    this.inputParams.startPointImageEditFlg = 1;
    this.inputParams.startPointImage = null;
    this.inputParams.startPointImageId = null;
  }

  setRewardTicketFile(data: string, id: number | null) {
    this.rewardTicketImageEditFlg = 1;
    this.rewardTicketImage = data;
    this.rewardTicketImageId = id;
  }

  unsetRewardTicketFile() {
    this.rewardTicketImageEditFlg = 1;
    this.rewardTicketImage = null;
    this.rewardTicketImageId = null;
  }

  goBack() {
    if (this.isFirstPage) {
      this.$router.back();
    } else {
      window.scrollTo(0, 0);
      this.isFirstPage = true;
    }
  }

  async goForward() {
    if (this.nextLabel === NextLabelEnum.SAVE) {
      //NEW_DEV-1893 cyber start
      this.inputParams.isPreview = 0;
      //NEW_DEV-1893 cyber end

      await this.callRegisterAPI();
      if (StampRegister.isSuccess) {
        await Flash.clear();
        await this.redirectWithSuccessAlert(
          "スタンプを保存しました。",
          `/stamps/${this.shopId}/list`,
          { shopName: this.shopName }
        );
      }
    } else {
      window.scrollTo(0, 0);
      this.isFirstPage = false;
      this.initRankShopStamps();
    }
  }

  async ticketPreview() {
    // NEW_DEV-1997 cyber start
    if (
      !this.rewardTicketTitle
    ) {
      await Flash.setErrorNow({
        message: "チケットタイトルは必須です。"
      } as ErrorAlert);
      return;
    }
    // NEW_DEV-1997 cyber end
    let type = 1;
    let isComplete = null as number | null;
    let rankNum = null as number | null;
    if (this.rewardTicketModalType === RewardTicketType.Intermediate) {
      isComplete = isComplete ? 1 : 0;
    } else {
      type = 2;
      rankNum = this.selectingIndex + 1;
    }
    const request = {
      shopId: this.inputParams.shopId,
      type: type,
      isComplete: isComplete,
      rankNum: rankNum,
      title: this.rewardTicketTitle,
      imageEditFlg: this.rewardTicketImageEditFlg,
      image: this.rewardTicketImage,
      imageId: this.rewardTicketImageId,
      detail: this.rewardTicketContent,
      note: this.rewardTicketCaution,
      // NEW_DEV-1997 cyber start
      incompleteNum: this.isComplete ? this.inputParams.completeNum : this.previewCompNum,
      completeNum: this.inputParams.completeNum,
      previewRankTitle: this.previewRankTitle
      // NEW_DEV-1997 cyber end
    } as GetPreviewRequest;
    await StampTicketPreviewGet.get(request);
    if (StampTicketPreviewGet.isSuccess) {
      WindowOpen.preview(StampTicketPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: StampTicketPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  async preview() {
    this.inputParams.isPreview = 1;
    await this.callRegisterAPI();
    if (StampRegister.isSuccess) {
      WindowOpen.preview(StampRegister.getPreviewUrl);
    }
  }

  getShopName(shopId: number) {
    let shop = this.stampShopItems.find(i => Number(i.id) === shopId);
    return shop ? shop.name : "";
  }

  changeDateCallback(date: string) {
    this.inputParams.expirationDate = date;
  }

  addRankUpRewardCallback() {
    if (this.rankUpRewardCount < 99) {
      this.rankUpRewardCount++;
      this.rankSettingInputParams.push({
        id: null,
        rankNum: null,
        num: null,
        name: null
      } as RegisterRankSetting);
      this.rankTicketInputParams.push({
        id: null,
        title: null,
        detail: null,
        note: null,
        rankNum: null
      } as RegisterRankTicket);
      const shopId = this.inputParams.rankStamps![0].shopId;
      this.inputParams.rankStamps!.push({
        id: null,
        rank: this.rankUpRewardCount,
        shopId: shopId,
        stamp: null
      } as RegisterRankStamp);
      for (const rankShopStamp of this.rankShopStampInputParams) {
        const shopId = rankShopStamp.stamps[0].shopId;
        rankShopStamp.stamps.push({
          shopId: shopId,
          rank: this.rankUpRewardCount,
          stamp: null
        } as RegisterRankStamp);
      }
    }
  }

  removeRankUpRewardCallback() {
    if (this.rankUpRewardCount > 1) {
      this.rankUpRewardCount--;
      this.rankSettingInputParams.pop();
      this.rankTicketInputParams.pop();
      this.inputParams.rankStamps!.pop();
      for (const rankShopStamp of this.rankShopStampInputParams) {
        rankShopStamp.stamps.pop();
      }
    }
  }

  async openIntermediateTicketCallback(num: number) {
    this.rewardValidationErrors = [];
    this.rewardTicketModalType = RewardTicketType.Intermediate;
    this.isComplete = false;
    this.selectingIndex = num - 1;
    this.showRewardTicketModal = true;
    this.rewardTicketModalTitle = "途中特典チケット設定";
    this.rewardTicketModalText = `${num}回目の特典チケット設定を行なってください`;
    const index = num - 1;
    const incompTicketTmp = index == 0 ? this.inputParams.incompleteFirstTicket : this.inputParams.incompleteSecondTicket;
    this.initRewardTicketImageUrl = incompTicketTmp.image;
    if (this.initRewardTicketImageUrl == null) {
      this.initRewardTicketImageUrl = "";
    }
    // NEW_DEV-1998 cyber start
    this.resetImage++;
    // NEW_DEV-1998 cyber end
    this.rewardTicketTitle = incompTicketTmp.title;
    this.rewardTicketImage = incompTicketTmp.image;
    this.rewardTicketContent = incompTicketTmp.detail;
    this.rewardTicketCaution = incompTicketTmp.note;

    // NEW_DEV-1997 cyber end
    this.previewCompNum = incompTicketTmp.completeNum;
    // NEW_DEV-1997 cyber end
  }

  async openCompleteTicketCallback() {
    this.rewardValidationErrors = [];
    this.rewardTicketModalType = RewardTicketType.Intermediate;
    this.isComplete = true;
    this.showRewardTicketModal = true;
    this.rewardTicketModalTitle = "コンプリート特典チケット設定";
    this.rewardTicketModalText =
      "コンプリート特典チケット設定を行なってください";
    this.initRewardTicketImageUrl = this.compTicketCompleteInputParams.image;
    if (this.initRewardTicketImageUrl == null) {
      this.initRewardTicketImageUrl = "";
    }
    // NEW_DEV-1998 cyber start
    this.resetImage++;
    // NEW_DEV-1998 cyber end
    this.rewardTicketTitle = this.compTicketCompleteInputParams.title;
    this.rewardTicketImageEditFlg = this.compTicketCompleteInputParams.imageEditFlg;
    this.rewardTicketImage = this.compTicketCompleteInputParams.image;
    this.rewardTicketImageId = this.compTicketCompleteInputParams.imageId;
    this.rewardTicketContent = this.compTicketCompleteInputParams.detail;
    this.rewardTicketCaution = this.compTicketCompleteInputParams.note;
  }

  openRankTicketCallback(num: number) {
    this.rewardValidationErrors = [];
    this.rewardTicketModalType = RewardTicketType.Rank;
    this.selectingIndex = num - 1;
    this.showRewardTicketModal = true;
    this.rewardTicketModalTitle = "ランク別特典チケット設定";
    this.rewardTicketModalText =
      this.getRankName(num) + "の設定を行なってください";
    this.initRewardTicketImageUrl = null;
    const index = num - 1;
    if (this.rankTicketInputParams[index] !== undefined) {
      this.rankTicketInputParams.push({
        id: null,
        title: null,
        detail: null,
        note: null,
        rankNum: null,
        imageEditFlg: 0,
        image: null,
        imageId: null
      } as RegisterRankTicket);
    }
    this.initRewardTicketImageUrl = this.rankTicketInputParams[index].image;
    if (this.initRewardTicketImageUrl == null) {
      this.initRewardTicketImageUrl = "";
    }
    //NEW_DEV-1999 cyber start
    this.resetImage++;
    //NEW_DEV-1999 cyber end
    this.rewardTicketTitle = this.rankTicketInputParams[index].title;
    this.rewardTicketImageEditFlg = this.rankTicketInputParams[
      index
    ].imageEditFlg;
    this.rewardTicketImage = this.rankTicketInputParams[index].image;
    this.rewardTicketImageId = this.rankTicketInputParams[index].imageId;
    this.rewardTicketContent = this.rankTicketInputParams[index].detail;
    this.rewardTicketCaution = this.rankTicketInputParams[index].note;

    // NEW_DEV-1997 cyber end
    this.previewRankTitle = this.rankSettingInputParams[index].name;
    // NEW_DEV-1997 cyber end
  }

  openRankSettingCallback(num: number) {
    this.rankValidationErrors = [];
    this.showRankSettingModal = true;
    this.selectingIndex = num - 1;
    const rankName = this.getRankName(num);
    this.rankSettingModalTitle = rankName;
    this.rankSettingModalText = rankName + "の設定を行なってください";
    const index = num - 1;
    this.rankName = this.rankSettingInputParams[index].name;
    this.completeCount = this.rankSettingInputParams[index].num;
  }

  doSaveOnRewardTicketModal() {
    const index = this.selectingIndex;
    if (!this.rewardValidation()) {
        return;
    }

    this.showRewardTicketModal = false;
    switch (this.rewardTicketModalType) {
      case RewardTicketType.Intermediate:
        // 途中特典チケット設定
        if (this.isComplete) {
          // コンプリート特典チケット
          this.compTicketCompleteInputParams.title = this.rewardTicketTitle;
          this.compTicketCompleteInputParams.detail = this.rewardTicketContent;
          this.compTicketCompleteInputParams.note = this.rewardTicketCaution;
          this.compTicketCompleteInputParams.imageEditFlg = this.rewardTicketImageEditFlg;
          this.compTicketCompleteInputParams.image = this.rewardTicketImage;
          this.compTicketCompleteInputParams.imageId = this.rewardTicketImageId;
        } else {
          // 途中特典チケット
          let incompTicketTmp = {
            title: this.rewardTicketTitle,
            detail: this.rewardTicketContent,
            note: this.rewardTicketCaution,
            imageEditFlg: this.rewardTicketImageEditFlg,
            image: this.rewardTicketImage,
            imageId: this.rewardTicketImageId,
            completeNum: this.compTicketInputParams[index].completeNum
          }
          if (incompTicketTmp.imageEditFlg === undefined) {
            if (incompTicketTmp.image) {
              incompTicketTmp.imageEditFlg = 1;
            } else {
              incompTicketTmp.imageEditFlg = 0;
            }
          } else if (incompTicketTmp.imageEditFlg && incompTicketTmp.image == null) {
            incompTicketTmp.imageEditFlg = 0;
          }
          if (incompTicketTmp.imageId === undefined) {
            incompTicketTmp.imageId = null;
          }
          if (incompTicketTmp.imageEditFlg == 0 || incompTicketTmp.imageId != null) {
            delete incompTicketTmp.image;
          }

          if (index == 0) {
            this.inputParams.incompleteFirstTicket = incompTicketTmp;
          } else {
            this.inputParams.incompleteSecondTicket = incompTicketTmp;
          }
        }
        this.rewardTicketImage = null;
        this.initRewardTicketImageUrl = null;
        break;
      case RewardTicketType.Rank:
        // ランク別特典チケット設定
        this.rankTicketInputParams[index].rankNum = index + 1;
        this.rankTicketInputParams[index].title = this.rewardTicketTitle;
        this.rankTicketInputParams[index].detail = this.rewardTicketContent;
        this.rankTicketInputParams[index].note = this.rewardTicketCaution;
        this.rankTicketInputParams[
          index
        ].imageEditFlg = this.rewardTicketImageEditFlg;

        this.rankTicketInputParams[index].image = this.rewardTicketImage;
        this.rankTicketInputParams[index].imageId = this.rewardTicketImageId;

        this.rewardTicketImage = null;
        this.initRewardTicketImageUrl = null;
        break;
    }
  }

  private rewardValidation() {
    this.rewardValidationErrors = [];
    if (!this.rewardTicketTitle) {
        this.rewardValidationErrors.push('チケットタイトルは必須です。');
    }
    if (!this.rewardTicketContent) {
        this.rewardValidationErrors.push('チケット内容は必須です。');
    }
    if (!this.rewardTicketCaution) {
        this.rewardValidationErrors.push('注意書きは必須です。');
    }

    this.$refs.rewardModal.$children[0].$el.scrollTop = 0;
    return this.rewardValidationErrors.length == 0;
  }

  doSaveOnRankSettingModal() {
    const index = this.selectingIndex;
    this.rankSettingInputParams[index].rankNum = index + 1;
    if (!this.rankValidation(index, this.rankSettingInputParams[index].rankNum)) {
        return;
    }

    this.showRankSettingModal = false;
    this.rankSettingInputParams[index].name = this.rankName;
    this.rankSettingInputParams[index].num = this.completeCount;
  }

  private rankValidation(i: number, rankNum: number) {
    this.rankValidationErrors = [];
    if (!this.rankName) {
      this.rankValidationErrors.push(rankNum + 'stランクのランク名は必須です。');
    }
    if (!this.completeCount) {
      this.rankValidationErrors.push(rankNum + 'stランクのランク条件設定は必須です。');
    }
    // NEW_DEV-644 cyber start
    if (this.completeCount != null && this.completeCount < 1) {
      this.rankValidationErrors.push('コンプリート回数を入力してください。');
    }
    // NEW_DEV-644 cyber end
    return this.rankValidationErrors.length == 0;
  }

  private async callRegisterAPI() {
    await StampRegister.register(this.createRegisterRequest());
    if (!StampRegister.isSuccess) {
      await Flash.setErrorNow({
        message: StampRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private createDetailRequest() {
    return {
      id: Number(this.id)
    } as DetailRequest;
  }

  private createRegisterRequest() {
    const request = { ...this.inputParams };
    request.shopId = this.shopId;
    switch (request.expirationType) {
      case 1:
      case 2:
        request.expirationNum = this.expirationNums[request.expirationType];
        delete request.autoUpdatingNum;
        break;
      case 3:
        if (request.autoUpdatingType) {
          request.autoUpdatingNum = this.autoUpdatingNums[
            request.autoUpdatingType
          ];
        }
        delete request.expirationNum;
        break;
    }

    if (request.mainImageEditFlg == 0 || request.mainImageId != null) {
      //NEW_DEV-1899 cyber start
      if (request.isPreview == 1){
        request.mainImageEditFlg = 1
      }else {
        delete request.mainImage;
      }
      //NEW_DEV-1899 cyber end
    }
    if (request.bgImageEditFlg == 0 || request.bgImageId != null) {
      delete request.bgImage;
    }
    if (
      request.startPointImageEditFlg == 0 ||
      request.startPointImageId != null
    ) {
      delete request.startPointImage;
    }

    // 途中特典付与が有効な場合
    if (request.incompleteFlg === 1) {
      request.incompleteFirstTicket = this.inputParams.incompleteFirstTicket;
      if (!request.incompleteFirstTicket.title) {
        request.incompleteFirstTicket = null;
      }
      request.incompleteSecondTicket = this.inputParams.incompleteSecondTicket;
      if (!request.incompleteSecondTicket.title) {
        request.incompleteSecondTicket = null;
      }
    }

    // コンプリートチケット
    if (this.compTicketCompleteInputParams.title) {
      const compRequest = {
        ...this.compTicketCompleteInputParams,
        isComplete: 1
      };

      //コンプリートスタンプ数を管理画面入力値に上書き
      compRequest.completeNum = this.inputParams.completeNum;

      if (compRequest.imageEditFlg === undefined) {
        if (compRequest.image) {
          compRequest.imageEditFlg = 1;
        } else {
          compRequest.imageEditFlg = 0;
        }
      }
      if (compRequest.imageId === undefined) {
        compRequest.imageId = null;
      }
      if (compRequest.imageEditFlg == 0 || compRequest.imageId != null) {
        delete compRequest.image;
      }
      request.completeTicket = compRequest;
    }

    if (request.autoFlg === 1) {
      delete request.userShops;
    }

    if (request.rankFlg === 1) {
      // ランク設定が有効な場合
      request.rankSetting = [];
      for (let rankSetting of this.rankSettingInputParams) {
        if (rankSetting.name) {
          request.rankSetting.push({ ...rankSetting });
        }
      }
      if (!request.rankFuyoType || request.rankFuyoType === 1) {
        request.rankFuyoType = 1;
        // 報酬の種類がチケットの場合
        request.rankTickets = [];
        for (let rankTicket of this.rankTicketInputParams) {
          if (rankTicket.image) {
            rankTicket.imageEditFlg = 1;
          }
          if (rankTicket.title) {
            request.rankTickets.push({ ...rankTicket });
          }
        }
        delete request.rankStamps;
      } else {
        // 報酬の種類がスタンプの場合
        delete request.rankTickets;
        if (request.rankFuyoType === 2 && this.inputParams.stampSettingType) {
          // ランク特典がスタンプ付与、かつ、支店別の場合
          request.rankStamps = [];
          for (const rankShopStamp of this.rankShopStampInputParams) {
            for (const stamp of rankShopStamp.stamps) {
              request.rankStamps.push({ ...stamp });
            }
          }
        }
      }
    } else {
      delete request.rankSetting;
      delete request.rankTickets;
      delete request.rankStamps;
      delete request.rankFuyoType;
    }

    // オウンドアプリの契約がない場合、非表示項目のリクエストパラメータを削除
    if (this.agreements.ap === false && this.isNotAgreement() === false) {
      delete request.startPoint;
      request.startPointImageEditFlg = 0;
      delete request.startPointImage;
      delete request.startPointImageId;
    }

    return request;
  }

  private syncDetailParams() {
    const detailResult = StampDetail.getDetail;
    const userShops = detailResult.userShops;
    const completeTickets = detailResult.completeTickets;
    const rankSetting = detailResult.rankSetting;
    const rankTickets = detailResult.rankTickets;
    delete detailResult.completeTickets;
    delete detailResult.rankSetting;
    delete detailResult.rankTickets;
    this.inputParams = {
      ...this.inputParams,
      shopId: Number(this.shopId),
      ...detailResult,
      userShops: userShops,
      completeTickets: [],
      rankSetting: [],
      rankTickets: [],
      isPreview: 0
    };
    if (completeTickets) {
      for (let i = 0; i < completeTickets.length; i++) {
        if (completeTickets[i].isComplete) {
          // コンプリート特典チケット
          this.compTicketCompleteInputParams.id = completeTickets[i].id;
          this.compTicketCompleteInputParams.completeNum =
            completeTickets[i].num;
          this.compTicketCompleteInputParams.isComplete =
            completeTickets[i].isComplete;
        } else {
          // 途中特典チケット
          if (this.compTicketInputParams[i]) {
            this.compTicketInputParams[i].id = completeTickets[i].id;
            this.compTicketInputParams[i].completeNum = completeTickets[i].num;
            this.compTicketInputParams[i].isComplete =
              completeTickets[i].isComplete;
          } else {
            this.compTicketInputParams[i] = {
              id: completeTickets[i].id,
              completeNum: completeTickets[i].num,
              isComplete: 0
            } as RegisterCompleteTicket;
          }
        }
      }
    }
    if (rankSetting) {
      for (let i = 0; i < rankSetting.length; i++) {
        if (this.rankSettingInputParams[i]) {
          this.rankSettingInputParams[i].id = rankSetting[i].id;
          this.rankSettingInputParams[i].rankNum = rankSetting[i].rankNum;
        } else {
          this.rankSettingInputParams[i] = {
            id: rankSetting[i].id,
            rankNum: rankSetting[i].rankNum
          } as RegisterRankSetting;
        }
        this.rankUpRewardCount = i + 1;
      }
      this.rankSettingInputParams = this.rankSettingInputParams.sort((a, b) => {
        if (!a.rankNum || !b.rankNum) {
          return 0;
        }
        return a.rankNum < b.rankNum ? -1 : 0;
      });
    }
    if (rankTickets) {
      for (let i = 0; i < rankTickets.length; i++) {
        if (this.rankTicketInputParams[i]) {
          this.rankTicketInputParams[i].id = rankTickets[i].id;
          this.rankTicketInputParams[i].rankNum = rankTickets[i].rankNum;
        } else {
          this.rankTicketInputParams[i] = {
            id: rankTickets[i].id,
            rankNum: rankTickets[i].rankNum
          } as RegisterRankTicket;
        }
      }
      this.rankTicketInputParams = this.rankTicketInputParams.sort((a, b) => {
        if (!a.rankNum || !b.rankNum) {
          return 0;
        }
        return a.rankNum < b.rankNum ? -1 : 0;
      });
    }
    if (this.inputParams.expirationType) {
      this.expirationNums[
        this.inputParams.expirationType
      ] = this.inputParams.expirationNum;
    }
    if (this.inputParams.autoUpdatingType) {
      this.autoUpdatingNums[
        this.inputParams.autoUpdatingType
      ] = this.inputParams.autoUpdatingNum;
    }
    this.initMainImageUrl = this.inputParams.mainImage;
    this.initBgImageUrl = this.inputParams.bgImage;
    this.initStampImageUrl = this.inputParams.startPointImage;
  }

  private initRankShopStamps() {
    this.rankShopStampInputParams = [];
    if (!this.inputParams.userShops || !this.rankSettingInputParams) {
      return;
    }

    for (const shopId of this.inputParams.userShops) {
      const rankShopStamp = { shopId: shopId, stamps: [] } as RankShopStamp;
      for (let i = 0; i < this.rankSettingInputParams.length; i++) {
        const rank = i + 1;
        let stampNum = null as number | null;
        if (this.inputParams.rankStamps) {
          const rankStamp = this.inputParams.rankStamps.find(
            s => s.shopId === shopId && s.rank === rank
          );
          rankStamp && (stampNum = rankStamp.stamp);
        }
        rankShopStamp.stamps.push({
          id: null,
          shopId: shopId,
          rank: rank,
          stamp: stampNum
        } as RegisterRankStamp);
      }
      this.rankShopStampInputParams.push(rankShopStamp);
    }
  }

  private incompleteChange(num, val) {
      switch (num) {
        case 1:
          this.inputParams.incompleteFirst = !val ? 0 : 1;
          this.inputParams.incompleteFirstTicket.completeNum = val;
          if (!val) {
            this.inputParams.incompleteFirstTicket = {};
          }
          break;
        case 2:
          this.inputParams.incompleteSecond = !val ? 0 : 1;
          this.inputParams.incompleteSecondTicket.completeNum = val;
          if (!val) {
            this.inputParams.incompleteSecondTicket = {};
          }
          break;
      }
  }
}
